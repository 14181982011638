<script setup lang="ts">
import Categories from "~/components/pages/catalog/CategoriesCarousel.vue";
import { RoleEnum } from "~/types/User";

const globalStore = useGlobalStore()
const props = defineProps({
    role: String
})

const current_path = computed(() => {
    if (props.role === RoleEnum.MASTER) {
        return 'task_category'
    }
    return 'master_category'
})

const specificationCarouselItems = computed(() => {
    return globalStore.specifications.filter(item => !item.parent_id && item.is_popular)
})

const specificationCards = computed(() => {
    return globalStore.specifications.filter(item => !item.parent_id && item.is_recommend && !!item.photo)
})

function getChildren(parent_id: string, index: number = 0){
    let show_items = 5
    if(getGridClass(index)?.includes('sm_card')){
        show_items = 2
    }
    return globalStore.specifications.filter(item => item?.parent_id === parent_id).slice(0, show_items)
}


function getGridClass(index: number) {
    let totalElementsBeforeRow = 0;
    let currentRowSize = 0;
    let rowClass = '';

    for (let row = 0; totalElementsBeforeRow <= index; row++) {
        if (row % 2 === 0) {
            currentRowSize = 2;
            rowClass = 'md:col-span-3 lg_card';
        } else {
            currentRowSize = 3;
            rowClass = 'md:col-span-2 sm_card';
        }

        if (index < totalElementsBeforeRow + currentRowSize) {
            return rowClass;
        }

        totalElementsBeforeRow += currentRowSize;
    }
}

</script>

<template>
    <div class="">
        <slot />
        <Categories :items="specificationCarouselItems" :role="props.role" has_autoplay class="mt-8 sm:mt-14 mb-8" />

        <div class="grid grid-flow-row gap-3 grid-cols-2 xl:grid-cols-6">
            <UiCard
                v-for="(item, index) in specificationCards"
                @click.prevent="navigateTo({name: current_path, params: {slug: item.slug}})"
                class="group transition-all duration-300 no-select border-0 shadow-none ys-category-box-element bg-[#F6F8FA] hover:bg-[#F7FEF8] p-3 md:p-6 min-h-[120px] md:min-h-[240px] h-100p relative cursor-pointer"
                :class="getGridClass(index)">
                <div class="background_image absolute top-[30px] md:top-0 bottom-0 right-0 left-0 ltr:transform-none rtl:scale-x-[-1]" :style="`background-image: url(${item.photo.url})`"></div>
                <div class="flex flex-col justify-between h-full relative">
                    <h1 class="text-[14px] leading-[14px] sm:text-2xl sm:font-medium">{{ item.name }}</h1>
                    <div
                        class=" hidden sm:flex -ml-1"
                         :class="{
                        'flex-col items-start max-w-[90%]': getGridClass(index)?.includes('sm_card'),
                        'flex-wrap max-w-[50%]': !getGridClass(index)?.includes('sm_card'),
                    }">
                        <nuxt-link 
                            @click.stop.prevent
                            v-for="child in getChildren(item.documentId, index)"
                            class="border border-gray-200 bg-white text-gray-500 transition-all hover:bg-primary-700 hover:border-primary-700 hover:text-white lowercase rounded-full text-xs px-4 py-2 m-1 h-8 block max-w-[120px] truncate line-clamp-1"
                            :to="{name: current_path, params: {slug: child.slug}}">
                            {{ child.name }}
                        </nuxt-link>
                        <nuxt-link :to="{name: current_path, params: {slug: item.slug}}" class="border border-gray-200 bg-white text-gray-500 transition-all hover:bg-primary-700 hover:border-primary-700 hover:text-white lowercase rounded-full text-xs px-4 py-2 m-1 shrink-0">
                            {{ $t('all services') }}
                            <Icon name="ri:arrow-right-up-line" class="ml-1 -mt-0.5" /> 
                        </nuxt-link>
                    </div>
                </div>
            </UiCard>
        </div>

    </div>
</template>
<style scoped lang="scss">
.no-select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.ys-category-box-element {
    overflow: hidden;
    border-radius: 20px;
}

.background_image {
    background-size: auto 100%;
    background-position: right center;
    background-repeat: no-repeat;
    transition: all .2s;

    .group:hover &{
        background-size: auto 110%;
    }
}
</style>